import React from "react"
import Layout from "../components/layout/layout"
import { graphql } from "gatsby"
import Hero from "../components/layout/hero"
import Navbar from "../components/layout/navbar"
import HealthEntry from "../components/services/health-and-safety"
import Accreditations from "../components/accreditations"
import SEO from "../components/seo"

export default ({ data }) => {
  return(
    <Layout>
      <SEO
        title="Health and Safety | Caledonian Tree Services"
        canonicalUrl={data.site.siteMetadata.siteUrl + "/contact-success/"}
        description="Health and saftey is important to us at Caledonian Tree Services, Forestry and Tree surgery can be dangerous professions which is why we only employ fully qualified staff with a no-nonsense approach to health and safety."
      />
      <Hero
        hero={data.hero.desktopImage.fluid}
        heroMobile={data.heroMobile.mobileImage.fluid}
        logo={data.logo.childImageSharp.fixed}
        logoMobile={data.logoMobile.childImageSharp.fixed}
        arb={data.arb.childImageSharp.fixed}
        strapline="Health &amp; Safety"
        straplineMobile="Health &amp; Safety"/>
      <Navbar/>
      <section className="section">
        <div className="container content-container">
          {data.allContentfulHealthAndSafety.edges.map(({ node }, index) => (
            <HealthEntry key={index} image={node.image} title={node.title} link={node.link} text={node.text.childMarkdownRemark.html} index={index} />
          ))}
        </div>
      </section>
      <Accreditations/>
    </Layout>
  )
}

export const query = graphql`
  {
   allContentfulHealthAndSafety(sort: { fields: [order], order: ASC }) {
    edges{
      node{
        title
        text{
          childMarkdownRemark{
            html
          }
        }
      }
    }
  }
  hero: contentfulHeroImage(desktopImage: {title: {eq: "health-hero"}}) {
    desktopImage{
      fluid{
        src
      }
    }
  }
  heroMobile: contentfulHeroImage(mobileImage: {title: {eq: "health-hero-mobile"}}) {
    mobileImage {
      fluid {
        src
      }
    }
  }
  logo: file(relativePath: { eq: "logo.png" }) {
    childImageSharp {
      fixed(width: 150, height: 150) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
  logoMobile: file(relativePath: { eq: "logoMobile.png" }) {
    childImageSharp {
      fixed(width: 50, height: 50) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
  arb: file(relativePath: { eq: "ARB-Approved2.png" }) {
    childImageSharp {
      fixed(width: 125, height: 150) {
        ...GatsbyImageSharpFixed_withWebp_noBase64
      }
    }
  }
}
`
